.reportarea{
    float: left;
    width: 100%;
    background: #cc0000;
    padding: 15px 0 5px 0;
    margin-bottom: 25px;
}
.reportarea li{
    list-style: none;
    display: inline;
}
.reportarea a{
    color: #fff;
    text-decoration: none;
    border: 1px solid #fff;
    padding: 5px 10px 5px 10px;
    text-transform: uppercase;
    font-size: 15px;
    margin: 0 20px 0 20px;
}
.heads{
    float: left;
    width: 100%;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 700;
    border: 1px dashed #a9a9a9;
    padding: 10px 0 10px 0;
    margin-bottom: 45px;
}
.form-group{
    float: left;
    text-align: left;
    padding-right: 20px;
    margin-bottom: 20px;
}
.form-group label{
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 700;
}
.btnclass{
    width: 100%!important;
}
.mybtn{
    border: none;
    background: #cc0000;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px;
    padding: 8px 20px 8px 20px;
}
.filters{
    float: left;
    width: auto;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}
.srcbtn{
    margin-top: 28px;
}
.tableheads{
    width: 100%;
}
.tableheads th{
    background: #404040;
    color: #fff;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
}
.tableheads th, .tableheads td{
    border-style: solid;
    border-width: 1px;
    border-color: #dadada;
}
.reportex{
    float: right;
}
.reportex button{
    background: #cc0000;
    color: #fff;
    border: none;
    text-transform: uppercase;
    font-size: 15px;
    padding: 5px 15px 5px 15px;
    margin-top: 30px;
}
.taskidate{
    min-width: 300px;
}
.todaydt{
    float: left;
    width: 100%;
    margin-top: 40px;
}
.todaydt .heads {
    margin-bottom: 20px;
}